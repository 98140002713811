<template>
  <div class="contentBox">
    <div>
      <el-dialog v-model="dialog_Switch" title="硬件开发配置" width="70%" :modal="false">
        <el-table :data="table_Data" style="width: 100%">
          <el-table-column fixed prop="name" label="硬件元素类型" width="240">
            <template #default="scope">
              <el-select v-model="scope.row.name" placeholder="请选择">
                <el-option style="color: #000;font-weight: 500;" v-for="i in configuration" :key="i.value" :value="i.name"
                  @click="handel_Change(i.serial_number, scope.row)">
                </el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column prop="ID" label="硬件终端ID" width="240">
            <template #default="scope">
              <el-input v-model="scope.row.ID" readonly placeholder=""></el-input>
            </template>
          </el-table-column>

          <el-table-column prop="" label="" width="400">
          </el-table-column>

          <el-table-column fixed="right" label="操作" width="">
            <template #default>
              <el-button type="primary" size="small" @click="">连接</el-button>
              <el-button type="primary" size="small" @click="remove()">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog width="30%" v-model="inner_Visible" append-to-body class="previewDialog">
          <span>
            请根据设计所需的软件进行连接，进入相关的应用程序。例如，可以使用socket进行IP地址的连接，进入相关的socket参考应用程序。选手可以根据自己的设计需要，对该硬件元素及相应的指标或图像、数据呈现等进行进一步进行设计开发实现。
          </span>
        </el-dialog>
        <div class="saveBox">
          <el-button type="primary" @click="create()">
            <span style="vertical-align: middle"> 创建 </span>
          </el-button>
          <el-button type="primary" @click="keep()">
            <span style="vertical-align: middle"> 保存 </span>
          </el-button>
          <el-button class="butext" type="text" @click="inner_Visible = true">查看详情</el-button>
        </div>
      </el-dialog>
    </div>


    <div>
      <el-dialog v-model="dialog_Visible" title="指标呈现" width="75%">
        <el-tabs v-model="active_Name" @tab-click="handleClick">
          <el-tab-pane label="硬件终端指标" name="first">硬件终端指标</el-tab-pane>
          <el-tab-pane label="5G系统仿真指标" name="second">5G系统仿真指标</el-tab-pane>
        </el-tabs>
        <div class="saveBox">
          <el-button type="primary" @click="">
            <span style="vertical-align: middle"> 保存 </span>
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import Footer from "../home/footer.vue";
import Header from "../home/header.vue";
import { Menu, EditPen, Message, ArrowRight } from "@element-plus/icons-vue";
export default {
  name: "home",
  props: {
    // msg: String
  },
  components: {
    Header,
    Footer,
    Menu,
    EditPen,
    Message,
    ArrowRight,
  },
  data() {
    return {
      inner_Visible: false,
      configuration: "",
      dialog_Switch: false,
      dialog_Visible: false,
      active_Name: 'second',
      table_Data: [
        {
          name: '',
          IP: '',
          ID: '',
          mode: '',
          address: '',
        },
      ],
    }

  },
  watch: {},
  mounted() {
  },
  methods: {
    //打开弹框
    open(name, data) {
      if (name == '硬件开发配置') {
        this.configuration = data
        this.dialog_Switch = true;
      } else if (name == '指标呈现') {
        this.dialog_Visible = true;
      }
    },

    //表格创建
    create() {
      let list =
      {
        name: '',
        IP: '',
        ID: '',
        mode: '',
        address: '',
      }
      this.table_Data.push(list)
    },
    //表格删除
    remove() {
      if (this.table_Data.length > 1) {
        this.table_Data.pop();
      } else {
        this.$message.error("基础列无法删除");
      }
    },
    //ID赋值
    handel_Change(id, row) {
      row.ID = id
    },
  }
};
</script>

<style scoped>


@import "./css/tree.css";
@import "./css/equipment.css";
</style>